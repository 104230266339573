import { render, staticRenderFns } from "./MenuBar.vue?vue&type=template&id=3b001376"
import script from "./MenuBar.vue?vue&type=script&lang=js"
export * from "./MenuBar.vue?vue&type=script&lang=js"
import style0 from "./MenuBar.vue?vue&type=style&index=0&id=3b001376&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3DesktopSubMenu: require('/var/app/components/Theme3/Desktop/SubMenu.vue').default})
